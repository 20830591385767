/*******************************
         Site Overrides
*******************************/
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins-extrabolditalic.woff2') format('woff2'),
         url('../fonts/poppins-extrabolditalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins-extrabold.woff2') format('woff2'),
         url('../fonts/poppins-extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins-bolditalic.woff2') format('woff2'),
         url('../fonts/poppins-bolditalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins-bold.woff2') format('woff2'),
         url('../fonts/poppins-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins-mediumitalic.woff2') format('woff2'),
         url('../fonts/poppins-mediumitalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins-medium.woff2') format('woff2'),
         url('../fonts/poppins-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins-italic.woff2') format('woff2'),
         url('../fonts/poppins-italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins-regular.woff2') format('woff2'),
         url('../fonts/poppins-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

//App
.left-round {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

//Order Details
.orders-table-container {
    background-color: @secondaryColor;   
}

//Buffer Details Panel
.details-panel-column {
    background-color: @darkSecondaryColor;
    padding: 2rem !important;
    padding-top: 0px !important;
}
.numerical-details-buffer-parameters-minimum_order_quantity {
  background: @green !important;
}
.numerical-details-buffer-parameters-calculated_green_zone {
  background: @green !important;
}
.numerical-details-buffer-parameters-decoupled_lead_time {
  background: @yellow !important;
}
.numerical-details-buffer-parameters-average_daily_usage {
  background: @yellow !important;
}
.numerical-details-buffer-parameters-lead_time_factor {
  background: @red !important;
}
.numerical-details-buffer-parameters-variability_factor {
  background: @red !important;
}
.numerical-details-buffer-zones-grey {
  background: @grey !important;
}
.numerical-details-buffer-zones-red {
  background: @red !important;
}
.numerical-details-buffer-zones-yellow {
  background: @yellow !important;
}
.numerical-details-buffer-zones-green {
  background: @green !important;
}
.details-bottom-section table {
    border: 0px !important;
    order-collapse:separate !important; 
    border-spacing: 0em 0.5em !important;
}
.details-bottom-section .ui.definition.table td:nth-child(2) {
    background-color: @secondaryColor;
}
.details-bottom-section .ui.table tr td {
    border: 0px !important;
    padding: 0.5em 1em;
    font-weight: 500 !important;
}
.details-bottom-section .title {
    font-weight: 500;
}
.details-panel {
    padding: 1em !important;
    padding-top: 0px;
}
.close-details .ui.basic.button {
    background-color:white !important;
}
.details-top-section .content {
    padding-bottom: 0px !important;
}
.details-top-section span {
    font-weight:bold;
}
.order-card-header, .card-order-recommended-qty {
    color: @darkerPrimaryColor !important;
    font-size: 1.4em !important;
}


//Buffer Details Chart
.grey {
  fill: @grey;
  animation: fillBar 0.37s ease-out;
}
.red {
  fill: @red;
  animation: fillBar 0.37s ease-out;
}
.yellow {
  fill: @yellow;
  animation: fillBar 0.37s ease-out;
}
.green {
  fill: @green;
  animation: fillBar 0.37s ease-out;
}


//Nav
.top-nav a.item.selected {
    border-bottom: 5px @tertiaryColor solid !important;
}
.top-nav .item {
    outline: 0;
}
.top-nav.ui.menu {
    box-shadow: 0px 0px 0px 0 !important;
}
.logo-section {
    color: @darkerPrimaryColor;
}
.logo-section .header {
    color: @tertiaryColor !important;
}
.logo-section .header i {
  color: @darkerPrimaryColor !important;
}



//Full Height
body, .App, #root {
    height: 100%;
}
.dashboard {
    height: 100%;
}

//Order Table and Filters
.button.suggested-order-filter {
    background-color: @darkPrimaryColor !important;
    color: white !important;
}
.button.dynamic-fields-trigger {
    margin: 0 0.25em 0 0.25em !important;
}

.commit-undo-bar .button.secondary {
    background-color: @darkerSecondaryColor !important;
}

.commit-undo-bar .button.secondary:hover {
    background-color: lighten(@darkerSecondaryColor, 5) !important;
}
.layout-control-buttons .ui.button {
    background-color: @green !important;
}
.unassigned-text-warning {
    color: @red;
}
.ui.table thead th {
    background-color: @primaryColor !important;
    color:white !important;
}

.ui.table thead .subheader th {
    background-color: @slightlyLighterPrimaryColor !important;
}
.page-selector .button {
    background-color: @primaryColor !important;
    color: white !important;
}
td.variance-cell-surplus {
  background: @lighterPrimaryColor;
}
td.variance-cell-deficit {
  background: @lighterPrimaryColor;
}
td.expired {
  background: @grey;
}
td.expiry_this_week {
  background: @red;
}
td.expiry_next_week {
  background: @yellow;
}
td.expiry_two_weeks {
  background: @green;
}
td.expiry_three_weeks {
  background: @lighterPrimaryColor;
}
td.expiry_three_plus_weeks {
  background: @white;
}



td.quantity-in-black-zone {
  background: @black !important;
  color: @white !important;
}
td.quantity-in-grey-zone {
  background: @grey !important;
}
td.quantity-in-red-zone {
  background: @red !important;
}
td.quantity-in-yellow-zone {
  background: @yellow !important;
}
td.quantity-in-green-zone {
  background: @green !important;
}
td.quantity-in-blue-zone {
  background: @blue !important;
}

//Toastify
.Toastify__toast-container {
  border-radius: 0.5em;
}
.Toastify__toast--default {
    background: @secondaryColor;
    border-radius: 0.5em;
 }
  .Toastify__toast--info {
    background: @green;
    border-radius: 0.5em;
 }
  .Toastify__toast--success {
    background: @green;
    border-radius: 0.5em;
 }
  .Toastify__toast--warning {
    background: @yellow;
    border-radius: 0.5em;
 }
  .Toastify__toast--error {
    background: @red;
    border-radius: 0.5em;
 }






//Data Import
span.failed {
    color: @red;
}
.data-import {
    background-color: @secondaryColor;
}
.upload-buttons {
    background-color: @lighterPrimaryColor !important;
}


//Group Settings

.buffer-settings {
    background-color: @secondaryColor;
}
.dropdown a.label {
  background-color: @lightPrimaryColor !important;
  color: white !important;
}
.slider-track {
  background-color: @lightPrimaryColor;
}

.slider-handle {
  background-color: @tertiaryColor;
}

.rules-group .ui.label {
    background-color: @lightPrimaryColor !important;
    color:white !important;
}

//Admin Console
.admin-console-container {
  background-color: @secondaryColor;
}

//Calendar
.segment.calendar-segment {
  background-color: @secondaryColor !important;
}
.full-calendar h2 {
  color: @darkerPrimaryColor;
}
.fc-button-primary {
  background-color: @darkPrimaryColor;
  border: none;
}

.fc-button-primary:hover {
  background-color: darken(@darkPrimaryColor, 5);
  border: none;
}

.fc-button-primary:disabled {
  background-color: @lightPrimaryColor;
  border: none;
}
.fc-button-primary:disabled:hover {
  background-color: @lightPrimaryColor;
  border: none;
}

.fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active {
  background-color: @darkPrimaryColor;
  border: none;
}
.fc-button-primary:not(:disabled):active:hover, .fc-button-primary:not(:disabled).fc-button-active:hover {
  background-color: @darkPrimaryColor;
  border: none;
}
.fc-sun {
  background-color: white !important;
}
.working-day {
  background: lighten(@green, 40) !important;
}
.ordering-day {
  background: @lighterPrimaryColor;
}
.hoveredDay {
  border: 1px solid @darkerPrimaryColor !important;
}

.fc table {
    border-collapse: separate;
    border-spacing: 0.6em;
    font-size: 1em;
    border-radius: 0.5em;
    border:0px;
}
.fc table td, .fc table th {
  border-radius: 0.5em;
  border: 0px;
}

.fc table tbody td {
  padding: 0.4em 0.8em;
}

.fc-head-container.fc-widget-header, .fc-day-header.fc-widget-header, .fc-widget-content  {
  border: 0px;
}

th.fc-day-header, th.fc-day-header.fc-sun {
  background-color: @darkPrimaryColor !important;
  color:white;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

//Analytics

.analytics-segment {
  background-color: @secondaryColor !important;
  min-height: 91%;
}

.orders-table .ui.table td {
padding: 0.6em 0.3em !important;
}