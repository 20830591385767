/*******************************
         Site Overrides
*******************************/

.ui.inverted.red.buttons .button, .ui.inverted.red.button {
  box-shadow: 0px 0px 0px 2px @red inset !important;
  color: @red !important;
}

.ui.inverted.red.buttons .button:hover, .ui.inverted.red.button:hover {
    background-color: @red !important;
    color:white !important;
}

.ui.inverted.green.buttons .button, .ui.inverted.green.button {
  box-shadow: 0px 0px 0px 2px @green inset !important;
  color: @green !important;
}

.ui.inverted.green.buttons .button:hover, .ui.inverted.green.button:hover {
    background-color: @green !important;
    color:white !important;
}